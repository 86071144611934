import '../../asset/styles/SurveyBot.css'; 

interface ProgressBarProps {
  progressPercentage: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ( {progressPercentage} ) => {
  return (
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ width: `${progressPercentage}%` }} />
    </div>
  );
};


export default ProgressBar;
