import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { 
    FormGroup, 
    FormControlLabel, 
    Checkbox, 
    Slider, 
    TextField, 
    List, 
    ListItem, 
    ListItemIcon, 
    ListItemText,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Droppable, Draggable, DragDropContext } from "@hello-pangea/dnd";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ResponseType } from "../../common/types";
import dayjs, { Dayjs } from 'dayjs';

interface RenderInputProps {
  responseType?: ResponseType;
  userInput: string;
  setUserInput: Dispatch<SetStateAction<string>>;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
}

const RenderInputComponent: React.FC<RenderInputProps> = ({ responseType, userInput, setUserInput, onKeyDown, handleSubmit }) => {
  const [options, setOptions] = useState<string[]>([]);
  const type = responseType?.type ?? 'text'

  useEffect(() => {
    if (responseType && responseType.options) {
      setOptions(responseType.options);
    }
  }, [responseType]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
  };

  const handleMultiChoiceChange = (option: string, isChecked: boolean) => {
    const currentSelected = JSON.parse(userInput || '[]') as string[];
    const updatedOptions = isChecked 
      ? [...currentSelected, option]
      : currentSelected.filter(opt => opt !== option);
    setUserInput(JSON.stringify(updatedOptions));
  };

  const handleSliderChangeCommitted = (event: Event, newValue: number | number[]) => {
    setUserInput((newValue as number).toString());
  };

  const handleDateChange = (date: Dayjs | null) => {
    setUserInput(date ? date.format('DD/MM/YYYY') : '');
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
  
    const reorderedItems = Array.from(options);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
  
    setOptions(reorderedItems);
  
    setUserInput(reorderedItems.join(', ')); 
  
    //console.log("Updated Order:", reorderedItems);
  };
  
  const submitBtn = () => {
    return (
      <button className="survey-bot-send" onClick={() => handleSubmit()}>
        Send
      </button>
      )
  };

  switch (type.toLowerCase()) {
    case 'single-choice':
      return (
        <div className="survey-bot-options">
          {responseType.options.map((option, optIndex) => (
            <button
              key={optIndex}
              className="option-button"
              onClick={() => { 
                setUserInput(option);
                handleSubmit();
              }
            }
            >
              {option}
            </button>
          ))}
        </div>
      );

    case 'multi-choice':
      return (
        <div className="survey-bot-options">
          <FormGroup>
            {responseType.options.map((option, optIndex) => (
              <FormControlLabel
                key={optIndex}
                control={
                  <Checkbox
                    checked={JSON.parse(userInput || '[]').includes(option)}
                    onChange={(e) => handleMultiChoiceChange(option, e.target.checked)}
                    name={`multi-${optIndex}`}
                    value={option}
                  />
                }
                label={option}
              />
            ))}
          </FormGroup>
          {submitBtn()}
        </div>
      );

    case 'drop-down':
      return (
        <div className="dropdown-container">
          <select 
            onChange={(e) => setUserInput(e.target.value)} 
            value={userInput}
          >
            <option value="">Select an option</option>
            {responseType.options.map((option, optIndex) => (
              <option key={optIndex} value={option}>
                {option}
              </option>
            ))}
          </select>
          {submitBtn()}
        </div>
      );

    case 'slider':
      const maxOption = responseType?.options?.[0]; 
      const max = maxOption ? parseInt(maxOption, 10) : 5; 

      return (
        <div className="slider-container">
          <Slider
            aria-label="Slider"
            defaultValue={1}
            onChangeCommitted={handleSliderChangeCommitted} 
            valueLabelDisplay="auto"             
            step={1}
            marks
            min={1}
            max={max}
            sx={{ width: '100%' }}
          />          
          {submitBtn()}
        </div>
      );

    case 'money':
      return (
        <div className="money-input-container">
          <TextField
            label="Enter amount"
            placeholder="Amount in SGD"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            value={userInput} 
            onChange={handleInputChange} 
          />          
          {submitBtn()}
        </div>
      );

    case 'date':
      return (
        <div className="date-picker-container">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select Date"
              value={userInput ? dayjs(userInput) : null}
              onChange={handleDateChange}
            />
          </LocalizationProvider>
          {submitBtn()}
        </div>
      );      

    case 'rank':
      return (
        <div className="rank-input-container">
          <DragDropContext onDragEnd={handleOnDragEnd} >
            <Droppable droppableId={`droppable`}>
              {(provided) => (
                <List {...provided.droppableProps} ref={provided.innerRef}>
                  {options.map((option, optIndex) => (
                    <Draggable key={option} draggableId={`draggable-${optIndex}-${option}`} index={optIndex}>
                      {(provided) => (
                        <ListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <ListItemIcon>
                            <DragIndicatorIcon />
                          </ListItemIcon>
                          <ListItemText primary={`${option}`} />
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
          {submitBtn()}
        </div>
      );

    default:
      return (
        <div style={{width: '100%'}}>
        <input
          type="text"
          style={{width: '90%'}}
          className="survey-bot-input"
          placeholder="Type your message..."
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyDown={onKeyDown}
        />
        {submitBtn()}
        </div>
      );
  }
};

export default RenderInputComponent;
