export const COLORS = {
  RED_70: '#E73929',
  PRIMARY_BLUE_80: '#1E4BB8',
};

export const CLIENT = {
  WOG: 'wog',
  SINGPASS: 'singpass',
  DESIGNER: 'designer'
};

export const LANGUAGES = [
  { code: "en", name: "English" },
  { code: "zh", name: "Chinese" },
  { code: "ms", name: "Malay" },
  { code: "ta", name: "Tamil" },
  { code: "bn", name: "Bangla" },
];

export const hardcodedConversations = {
  // ... your existing hardcoded conversations
};