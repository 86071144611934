import { useEffect, useState } from 'react';
import { listWorkflows } from '../../services/api';
import { Dialog, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, DialogActions, Button, SelectChangeEvent } from '@mui/material';

const SettingsDialog = ({ open, onClose, onSettingsSave, apiBaseUrl }: any) => {
    const [selectedWorkflow, setSelectedWorkflow] = useState<string>('');
    //const [selectedPersona, setSelectedPersona] = useState<string>('');
    const [workflowOptions, setWorkflowOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true); 

    const handleSave = () => {
        onSettingsSave(selectedWorkflow);
        onClose();
    };

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await listWorkflows(apiBaseUrl);
    
            if (Array.isArray(response)) {
              const workflows = response.map((item) => ({
                label: item.workflowName, 
                value: item.workflowId,  
              }));
              setWorkflowOptions(workflows);
            } else {
                alert('Unexpected API response format');
            }
            } catch (error) {
                alert(error);
            } finally {
                setLoading(false); 
            }
        };

        fetchData();
    }, [apiBaseUrl]);

    const handleWorkflowChange = (event: SelectChangeEvent<string>) => {
        setSelectedWorkflow(event.target.value);
    };

    if (loading) {
    return <div>Loading...</div>;
    }

    return open ? (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <div>
        <FormControl fullWidth margin="normal">
            <InputLabel>Survey Template</InputLabel>
            <Select
            labelId="workflow-select-label"
            value={selectedWorkflow}
            onChange={handleWorkflowChange}
            label="Select Workflow"
            >
            {workflowOptions.map((workflow) => (
                <MenuItem key={workflow.value} value={workflow.value}>
                {workflow.label}
                </MenuItem>
            ))}
            </Select>
        </FormControl>
        </div>
        
        {/* <div>
          <FormControl fullWidth margin="normal">
            <InputLabel>Persona</InputLabel>
            <Select
              value={selectedPersona}
              onChange={(e) => setSelectedPersona(e.target.value)}
              label="Persona"
            >
              <MenuItem value="">Select Persona</MenuItem>
              <MenuItem value="persona1">Persona 1</MenuItem>
              <MenuItem value="persona2">Persona 2</MenuItem>
            </Select>
          </FormControl>
        </div> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
    ) : null;
};

const SettingsButton = ({ onSettingsOpen }: any) => {
    return (
        <button onClick={onSettingsOpen}>⚙️</button>
    );
};

export { SettingsDialog, SettingsButton };
