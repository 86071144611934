import React, { createContext, useState, ReactNode, useEffect } from 'react';

export interface AppContextType {
  apiBaseUrl: string;
  isLoggedIn: boolean;
  role: 'singpass' | 'wog' | 'designer' | null;
  login: (role: 'singpass' | 'wog' | 'designer') => void;
  logout: () => void;
}

export const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode, inApiBaseUrl: string }> = ({ children, inApiBaseUrl }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [role, setRole] = useState<'singpass' | 'wog' | 'designer' | null>(null);
  const [apiBaseUrl] = useState(inApiBaseUrl);

  useEffect(() => {
    const savedIsLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    const savedRole = localStorage.getItem('role') as 'singpass' | 'wog' | 'designer' | null;

    if (savedIsLoggedIn) {
      setIsLoggedIn(true);
      setRole(savedRole);
    }
  }, []);

  const login = (userRole: 'singpass' | 'wog' | 'designer') => {
    console.log('Logging in with role:', userRole);
    setIsLoggedIn(true);
    setRole(userRole);

    // Save login status and role to localStorage
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('role', userRole);
  };

  const logout = () => {
    setIsLoggedIn(false);
    setRole(null);

    // Clear login status and role from localStorage
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('role');
  };

  return (
    <AppContext.Provider value={{ apiBaseUrl, isLoggedIn, role, login, logout }}>
      {children}
    </AppContext.Provider>
  );
};
