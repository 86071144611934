import React from 'react';

interface SectionDividerProps {
  sectionId: number;
  sectionName: string;
  sectionRef: React.RefCallback<HTMLDivElement>;
}

const SectionDivider: React.FC<SectionDividerProps> = ({ sectionId, sectionName, sectionRef }) => {
  //console.log(`Rendering SectionDivider for Section ID: ${sectionId}`); 
  return (
    <div ref={sectionRef} className="section-divider" id={`section-divider-${sectionId}`}>
      <h2>{`${sectionName}`}</h2>
      <hr />
    </div>
  );
};

export default SectionDivider;
