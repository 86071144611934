import React from "react";
import { Button, Typography, Container } from "@mui/material";
import { styled } from "@mui/system";
import { handleLogin } from "../services/auth";
import { CLIENT } from "../common/constants";

const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  backgroundColor: "#fff",
});

const StyledButton = styled(Button)({
  backgroundColor: "#f0f0f0",
  color: "#000",
  borderRadius: "20px",
  padding: "10px 20px",
  marginTop: "16px",
  fontSize: "16px",
});

const LoginPage: React.FC = () => {
  return (
    <StyledContainer maxWidth="sm">
      {/* Menu Icon */}
      {/* <Box className="w-full flex justify-start">
        <Box component="span" className="material-icons cursor-pointer">
          menu
        </Box>
      </Box> */}

      {/* Title */}
      <Typography variant="h5" component="h1" className="mt-6 font-bold">
        MRSD Chatbot
      </Typography>

      {/* Welcome Message */}
      <Typography variant="h6" component="h2" className="text-center mt-8 font-bold">
        Welcome to Manpower Surveys Platform
      </Typography>
      <Typography variant="body1" className="text-center mt-2">
        Please select an option to complete your survey
      </Typography>

      {/* Login Buttons */}
      <StyledButton disabled variant="contained" 
            fullWidth
            onClick={() => handleLogin(CLIENT.WOG)}>
        Login with WOGAD
      </StyledButton>
      
      <StyledButton disabled variant="contained" 
            fullWidth
            onClick={() => handleLogin(CLIENT.SINGPASS)}>
        Login with Singpass
      </StyledButton>
      
      <StyledButton variant="contained" 
            fullWidth
            onClick={() => handleLogin(CLIENT.DESIGNER)}>
        Login as Survey Designer
      </StyledButton>
    </StyledContainer>
  );
};

export default LoginPage;
