import { FormEvent, useContext } from 'react';
import CryptoJS from 'crypto-js'; 
import { AppContext } from '../common/app-context';
import { getRespondentDataByNric } from '../services/api';

const ConsentForm: React.FC = () => {
  const context = useContext(AppContext);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const nricInput = formData.get('NRIC') as string;
    console.log(nricInput);
    try {
      const personData =  await getRespondentDataByNric(context.apiBaseUrl, nricInput)
      console.log('personData: ', personData);

      if (personData) {
        const secretKey = 'mySuperSecretKey123';
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(personData), secretKey).toString();
        window.location.href = `/surveybot?data=${encodeURIComponent(encryptedData)}&loginType=wogad`;
      } else {
        alert('NRIC not found in the dataset.');
      }
    } catch(e) {
        console.log('Error: ', e);
      }
    }

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <h1>Consent to giving details</h1>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="NRIC">NRIC</label>
          <input
            type="text"
            id="NRIC"
            name="NRIC"
            style={{
              display: 'block',
              width: '100%',
              padding: '8px',
              marginTop: '5px',
              marginBottom: '10px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label>Decision:</label>
          <div>
            <input type="radio" id="allow" name="decision" value="allow" defaultChecked />
            <label htmlFor="allow" style={{ marginLeft: '5px' }}>Allow</label>
          </div>
          <div>
            <input type="radio" id="deny" name="decision" value="deny" />
            <label htmlFor="deny" style={{ marginLeft: '5px' }}>Deny</label>
          </div>
        </div>
        <button
          type="submit"
          style={{
            padding: '10px 20px',
            backgroundColor: '#1E4BB8',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ConsentForm;
