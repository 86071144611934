import axios from 'axios';

export const translateText = async (apiBaseUrl, text, targetLanguage) => {
  try {
    if (targetLanguage !== 'en') {
      const response = await axios.post(`${apiBaseUrl}/api/translate`, {
        text,
        targetLanguage
      });
      console.log('Original Text:', text);
      console.log('Target Language:', targetLanguage);
      console.log('Translated Text:', response.data.translatedText);
      return response.data.translatedText;
    } else {
      return text;
    }
  } catch (error) {
    console.error("Error translating text:", error);
    return text;
  }
};

export const handleSpeak = async (apiBaseUrl, text, lang) => {
  try {
    const translatedText = lang !== "en" ? await translateText(apiBaseUrl, text, lang) : text;
    const utterance = new SpeechSynthesisUtterance(translatedText);
    utterance.lang = getSpeechLangCode(lang);
    speechSynthesis.speak(utterance);
  } catch (error) {
    console.error("Error in speech synthesis:", error);
  }
};

  export const getSpeechLangCode = (lang) => {
    const langMap = {
      en: "en-US",
      zh: "zh-CN",
      ms: "ms-MY", 
      ta: "ta-IN",
      bn: "bn-IN" 
    };
    return langMap[lang] || "en-US";
  };