import React from 'react';
import { Survey } from '../../common/types';

interface SegmentBarProps {
  surveyConfig: Survey | null;
  scrollToSection: (sectionId: number) => void;
  promptedSections: number[];
}

const SegmentBar: React.FC<SegmentBarProps> = ({ surveyConfig, scrollToSection, promptedSections }) => {
  if (!surveyConfig) return null;

  return (
    <div className="flex flex-row justify-center py-2.5 w-full z-1000 bg-white shadow-lg">
      {surveyConfig.sections.map((section, index) => {
        const isDisabled = !promptedSections.includes(section.id) && section.id !== 1; // Enable first button
        return (
          <button
            key={section.id}
            className="segment-button"
            onClick={() => scrollToSection(section.id)}
            disabled={isDisabled}
          >
            {section.sectionName === '' ? `Section ${index + 1}` : section.sectionName}
          </button>
        );
      })}
    </div>
  );
};

export default SegmentBar;
