import { MessageType } from "../common/types";

export const extractOptions = (text) => {
    const optionRegex = /-\s+([^\n]+)/g;
    const matches = [];
    let match;
    
    // Use a loop to find all matches in the text
    while ((match = optionRegex.exec(text)) !== null) {
      matches.push(match[1].trim());
    }
  
    return matches;
  }

  export const parseMessage = (messages: MessageType[]) => {
    // Create the JSON structure from the messages array
    return messages.map((msg) => ({
      text: msg.text,
      sender: msg.sender,
      language: msg.language || 'en',
      sectionId: msg.sectionId || 1,
      questionId: msg.questionId || 1,
      // Include any other properties if needed
    }));
  };

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  alert("Copied to clipboard!");
};

export const regenerateResponse = async (text) => {
  console.log("Regenerate here");
};

export const onFeedback = async(text, feedback) => {
  console.log("Feedback on bot response here");
}
  