import React, { useContext, useState } from 'react';
import Avatar from './Avatar';
import '../../asset/styles/SurveyBot.css';
import { copyToClipboard, regenerateResponse } from '../../utils/botActions';
import { LANGUAGES } from '../../common/constants';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import RefreshIcon from '@mui/icons-material/Refresh';
import LanguageIcon from '@mui/icons-material/Language';
import UndoIcon from '@mui/icons-material/Undo';
import { Box, FormControl, MenuItem, Select, IconButton } from '@mui/material';
import { translateText, handleSpeak } from '../../services/translate';
import ReactMarkdown from 'react-markdown';
import { AppContext } from '../../common/app-context';

interface MessageProps {
  text: string;
  sender: string;
  onUndo: (messageIndex: number) => void;
  messageIndex: number;
}

const Message: React.FC<MessageProps> = ({ text, sender, onUndo, messageIndex }) => {
  const [msg, setMsg] = useState<string>(text);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const context = useContext(AppContext);

  const handleChange = async (event) => {
    console.log('translating....')
    const selectedLangCode = event.target.value;
    setSelectedLanguage(selectedLangCode);
    const translatedText = await translateText(context.apiBaseUrl, msg, selectedLangCode);
    setMsg(translatedText);
  };

  const isUser = sender === 'user';

  return (
    <FormControl fullWidth>
      <Box
        className={`survey-bot-message ${isUser ? 'user' : 'bot'} flex flex-col relative mb-4 ${isUser ? 'pr-5' : 'pl-5'}`}
      >
        <Box
          className={`message-bubble max-w-[70%] p-4 rounded-lg shadow-md ${
            isUser ? 'bg-[#003366] text-white' : 'bg-[#E5E5EA] text-black'
          }`}
        >
          <Avatar isUser={isUser} />
          <Box className="chat">
            <ReactMarkdown>{msg}</ReactMarkdown>
          </Box>
        </Box>

        {isUser && (
          <Box className="flex justify-center mt-2 py-4">
            <IconButton onClick={() => onUndo(messageIndex - 1)} aria-label="Undo" className="undo-button">
              <UndoIcon />
            </IconButton>
          </Box>
        )}

        {!isUser && (
          <Box className="survey-bot-actions flex justify-center items-center">
            <button 
              onClick={() => handleSpeak(context.apiBaseUrl, msg, selectedLanguage)} 
              aria-label="Speak">
              <VolumeUpIcon />
            </button>
            <button onClick={() => copyToClipboard(text)} aria-label="Copy">
              <ContentPasteIcon />
            </button>
            <button onClick={() => regenerateResponse(msg)} aria-label="Regenerate">
              <RefreshIcon />
            </button>
            <Box className="flex items-center ml-0.5">
              <LanguageIcon />
              <Select
                value={selectedLanguage}
                onChange={handleChange}
                sx={{
                  "& .MuiSelect-select": {
                    paddingLeft: 0.5,
                  },
                  "& fieldset": {
                    display: 'none',
                  },
                }}
              >
                {LANGUAGES.map((lang) => (
                  <MenuItem key={lang.code} value={lang.code}>
                    {lang.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        )}
      </Box>
    </FormControl>
  );
};

export default Message;