import React, { useState } from 'react';

interface Credential {
  username: string;
  password: string;
}

const WogLoginPage: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleWogLogin = () => {
    // Replace with the actual validation logic
    const validCredentials: Credential[] = [
      { username: 'sean', password: 'sean' },
      { username: 'raghu', password: 'raghu' },
    ];

    const isValid = validCredentials.some(
      (cred: Credential) => cred.username === username && cred.password === password
    );

    if (isValid) {
      console.log(`Logging in with Username: ${username}`);
      // Redirect to the consent.html page after successful login
      window.location.href = '/consent-form';
    } else {
      alert('Invalid username or password. Please try again.');
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <h1>WOG Login</h1>
        <p>Please enter your credentials to log in with WOG AAD.</p>
        <div style={{ marginBottom: '10px' }}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setUsername(e.target.value)
            }
            style={{
              padding: '10px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              width: '100%',
              marginBottom: '10px',
            }}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
            style={{
              padding: '10px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              width: '100%',
            }}
          />
        </div>
        <button
          style={{
            padding: '10px 20px',
            backgroundColor: '#1E4BB8',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
          onClick={handleWogLogin}
        >
          Log in with WOG AAD
        </button>
      </div>
    </div>
  );
};

export default WogLoginPage;
