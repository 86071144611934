import './asset/styles/App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import WogLoginPage from './pages/WogLoginPage';
import ConsentForm from './pages/ConsentForm';
import SurveyBot from './pages/SurveyBot';
import SurveyDesignerLogin from './pages/SurveyDesignerLogin';
import LoginPage from './pages/LoginPage';
import SurveyDesignerBot from './pages/SurveyDesignerBot';

function App() {  
  return (
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/surveydesignerbot" element={<SurveyDesignerBot />} />
          <Route path="/surveybot" element={<SurveyBot />} />
          <Route path="/wog-login" element={<WogLoginPage />} />
          <Route path="/sd-login" element={<SurveyDesignerLogin />} />
          <Route path="/consent-form" element={<ConsentForm />} />
        </Routes>
      </Router>
  );
}

export default App;
