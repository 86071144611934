import '../../asset/styles/SurveyBot.css';
import UserAvatar from '../../asset/images/user-avatar.png'
import BotAvatar from '../../asset/images/bot-avatar.png';

interface AvatarProps {
  isUser: boolean;
}

const Avatar: React.FC<AvatarProps> = ({ isUser }) => {
  return (
    <div className={`message-avatar`}>
      <img src={isUser ? UserAvatar : BotAvatar} alt={isUser ? 'User Avatar' : 'Bot Avatar'} className="message-avatar" />
    </div>
  );
};

export default Avatar;
