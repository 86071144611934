import { Box, Typography } from '@mui/material';
import '../../asset/styles/SurveyBot.css'; 
import SegmentBar from './SegmentBar';
import ProgressBar from './ProgressBar';


const Header = () => {
  return (
    <header>
      <Box className="flex w-full bg-[#003366] text-white p-2.5 justify-center text-center gap-5 rounded-t-lg shadow-lg">

      <Typography
      variant='h4'
      >
        Survey Bot POC
      </Typography>    
      <div className="download-button" 
          //onClick={downloadTranscript}
      >
        <i className="fas fa-download"></i>
      </div>
    </Box>
  </header>
  );
};

export default Header;
