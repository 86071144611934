import { Grid2 } from "@mui/material";
import React, { useEffect, useRef } from "react";
import SectionDivider from "../layout/SectionDivider";
import Message from "./Message";
import { MessageType, Survey } from "../../common/types";


interface MessageListProps {
  messages: MessageType[];
  workflow: Survey;
  sectionRefs: React.RefObject<{ [key: number]: HTMLDivElement | null }>;
  onUndo: (messageIndex: number) => void;
  loading: boolean
}

const MessageList: React.FC<MessageListProps> = ({ messages, workflow, sectionRefs, onUndo, loading }) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  useEffect(() => {    
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const renderedMessages = messages.map((msg, index) => {
    const currentSectionId = msg.sectionId;
    let sectionDivider = null;

    const isFinalMessage = msg.text.toLowerCase().includes("thank you for your time");
    
    if (workflow && !isFinalMessage && currentSectionId && (index === 0 || messages[index - 2]?.sectionId !== currentSectionId)) {      
        sectionDivider = (
            <SectionDivider
                key={`section-divider-${currentSectionId}`}
                sectionId={currentSectionId}
                sectionName={workflow.sections[currentSectionId - 1].sectionName || `Section ${currentSectionId}`}
                sectionRef={(el) => {
                    sectionRefs.current[currentSectionId] = el;
                }}
            />
        );
    } 
    return (
        <React.Fragment key={`fragment-${currentSectionId}-${index}`}>
            {sectionDivider}
            <Message
                key={`message-${index}`}
                text={msg.text}
                sender={msg.sender}
                onUndo={onUndo}
                messageIndex={index}
            />
        </React.Fragment>
    );
});


  return (
    <Grid2 container className="flex flex-grow w-full items-center justify-center pb-20">
      {renderedMessages}
      {loading && <div className="loading">Generating response...</div>} 
      <div ref={bottomRef} />
    </Grid2>
  );
}

export default MessageList;