import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Grid2 } from '@mui/material';
import useSurveyBot from '../hooks/useSurveyBot';
import Header from '../components/layout/Header';
import ProgressBar from '../components/layout/ProgressBar';
import MessageList from '../components/surveybot/MessageList';
import { generateGeminiResponse, generateSectionSummary } from '../services/api';
import { Question, Progress, ChatHistory } from '../common/types';
import RenderInputComponent from '../components/surveybot/RenderInputComponent';
import SegmentBar from '../components/layout/SegmentBar';
import { AppContext } from '../common/app-context';
import LoginPage from './LoginPage';

const SurveyBot: React.FC = () => {
    const params = new URLSearchParams(window.location.search);
    const [progress, setProgress] = useState<Progress>({ currentSectionId: 1, currentQuestionId: 1, percentage: 0 });
    const encryptedData = params.get('data');
    const context = useContext(AppContext);

    const {
        messages,
        chatHistory,
        promptedSections,
        workflow,
        userContact,
        initialContext,
        setMessages,
        setChatHistory,
    } = useSurveyBot(encryptedData, progress);

    const [userInput, setUserInput] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [currentQuestion, setCurrentQuestion] = useState<Question>();
    const sectionRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
    let summarisedSections = [];

    useEffect(() => {
        if (workflow) {
            if (messages.length <= 0){
                const initialQuestion = workflow.sections[0].questions[0];
                setCurrentQuestion(initialQuestion);

                if (chatHistory.length <= 0) { 
                    handleUserInput(true);
                }

                console.log(workflow);
            }
            else {
                const totalSections = workflow.sections.length;
                const latestSectionId = messages[messages.length - 1].sectionId;
                const latestQnId = messages[messages.length - 1].questionId;
                const latestQn = workflow.sections[latestSectionId - 1].questions[latestQnId - 1];
                setCurrentQuestion(latestQn);
                setProgress({
                    currentSectionId: latestSectionId,
                    currentQuestionId: latestQnId,
                    percentage: (latestSectionId/totalSections)*100,
                });
            }
        }

    }, [workflow]);
    

    const scrollToSection = (sectionId: number) => {
        const sectionRef = document.querySelector(`#section-divider-${sectionId}`);
        const headerOffset = 135;
    
        if (sectionRef) {
            const elementPosition = sectionRef.getBoundingClientRect().top + window.scrollY; // Get the absolute position
            const offsetPosition = elementPosition - headerOffset; // Subtract the offset
    
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    };

    const handleUndo = async (messageIndex: number) => {
      const sectionId = messages[messageIndex].sectionId;
      const questionId = messages[messageIndex].questionId;
      const updatedMessages = messages.slice(0, messageIndex + 1);

      console.log('updatedMessages', updatedMessages);
      const updatedChatHistory = chatHistory.slice(0, messageIndex + 2);
      console.log('updatedChatHistory', updatedChatHistory);

      const totalSections = workflow.sections.length;
  
      setMessages(updatedMessages);
      setChatHistory(updatedChatHistory);
      setCurrentQuestion(workflow.sections[sectionId - 1].questions[questionId - 1]);
      setProgress({
        currentSectionId: sectionId,
        currentQuestionId: questionId,
        percentage: (sectionId/totalSections)*100,
    });
    };

    const updateProgress = async (botRes, userInputMsg) => {
        if (workflow) {
            const { sectionId: updatedSectionId, questionId: updatedQuestionId } = {
                sectionId: Number(botRes.sectionId),
                questionId: Number(botRes.questionId),
            };

            const isSectionComplete = updatedSectionId !== progress.currentSectionId;
            setCurrentQuestion(workflow.sections[updatedSectionId - 1].questions[updatedQuestionId - 1]);  
            const totalSections = workflow.sections.length;
    
            let progressPercentage = 0;
            console.log(isSectionComplete)
    
            if (isSectionComplete) {
                progressPercentage = ((updatedSectionId - 1) / totalSections) * 100;
                //console.log(progressPercentage);
                const sectionSummary = await generateSectionSummary(JSON.stringify([...chatHistory, userInputMsg]));
                const summary = {role: 'user', content: sectionSummary.text};
                summarisedSections.push(summary);
                setChatHistory(summarisedSections);
                console.log(sectionSummary);

            } 
    
            setProgress({
                currentSectionId: updatedSectionId,
                currentQuestionId: updatedQuestionId,
                percentage: progressPercentage,
            });
        }
    };

    const handleUserInput = async (isInitial: boolean = false) => {
        if (userInput.trim() === '' && !isInitial) return;
        setLoading(true);
        // Use initialContext for the first message, otherwise use userInput
        const userInputMsg: ChatHistory = { role: 'user', content: isInitial ? `start context: ${initialContext}` : userInput };

        
        if (!isInitial){
          setMessages((prevMessages) => [...prevMessages, { text: userInput, sender: 'user' }]);
        }
        
        console.log('here');
        setChatHistory((prevHistory) => [...prevHistory, userInputMsg]);
        setUserInput('');

        try {
            const botRes = await generateGeminiResponse(context.apiBaseUrl, JSON.stringify([...chatHistory, userInputMsg]));
            if (botRes.sectionId && botRes.questionId) {
                await updateProgress(botRes, userInputMsg);
            }
            await updateBotResponse(botRes);
        } catch (error) {
            console.error('Error generating response:', error);
        } finally {
            setLoading(false);
        }
    };

    const updateBotResponse = (botRes) => {
      const botMsg: ChatHistory = { role: 'assistant', content: botRes.text };
      setChatHistory((prevHistory) => [...prevHistory, botMsg]);
      setMessages((prevMessages) => [...prevMessages, { text: botRes.text, sender: 'assistant', language: 'en', sectionId: Number(botRes.sectionId) || 1, questionId: Number(botRes.questionId) || 1, }]);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleUserInput();
        }
    };

    return (
    <Box className="h-full w-full">
        <Grid2 container direction="row" className="items-center justify-center">
            <Grid2 sx={{ position: 'sticky', top: 0, zIndex: 2 }} size={{ xs: 12 }}>
                <Header />
                {userContact && (
                    <>
                        <SegmentBar surveyConfig={workflow} scrollToSection={scrollToSection} promptedSections={promptedSections} />
                        <ProgressBar progressPercentage={progress.percentage} />
                    </>
                )}
            </Grid2>
            {context.isLoggedIn ? (
                !workflow ? (
                    <Box className="w-full text-center p-4">
                        <p>No workflow is applied</p>
                    </Box>
                ) : (
                    <>
                        <Box className="flex flex-col w-full h-full">
                            <Box style={{ flex: 1, overflowY: 'auto' }} >
                                <MessageList 
                                    messages={messages}
                                    workflow={workflow}
                                    onUndo={handleUndo}
                                    sectionRefs={sectionRefs}
                                    loading={loading}
                                />
                            </Box>
                            <Box className="relative w-full bottom-0 p-4 bg-gray-200 shadow-lg rounded-t-lg">
                                <RenderInputComponent
                                    responseType={currentQuestion?.responseType}
                                    userInput={userInput}
                                    setUserInput={setUserInput}
                                    onKeyDown={handleKeyDown}
                                    handleSubmit={handleUserInput}
                                />
                            </Box>
                        </Box>
                    </>
                )
            ) : (
                <LoginPage />
            )}
        </Grid2>
    </Box>
    );
};

export default SurveyBot;
