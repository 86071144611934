import { useState, useEffect, useRef, useContext } from 'react';
import CryptoJS from 'crypto-js';
import { fetchTranscript, getActiveWorkflow, saveSurveyCSVToDB, saveSurveyTranscriptToDB } from '../services/api';
import { ChatHistory, MessageType, Survey, Progress } from '../common/types';
import { parseMessage } from '../utils/botActions';
import { AppContext } from '../common/app-context';

interface UserContact {
    email: string;
}

const useSurveyBot = (encryptedData: string | null, progress: Progress) => {
    const [messages, setMessages] = useState<MessageType[]>([]);
    const [chatHistory, setChatHistory] = useState<ChatHistory[]>([]);
    const [promptedSections, setPromptedSections] = useState<number[]>([]);
    const [workflow, setWorkflow] = useState<Survey>();
    const [userContact, setUserContact] = useState<UserContact | null>(null);
    const [initialContext, setInitialContext] = useState<string | null>(null);
    const initialized = useRef(false);
    const context = useContext(AppContext);

    useEffect(() => {
        if (messages.length > 0) {
            const lastMessage = messages[messages.length - 1];
            const lastSectionId = Number(lastMessage.sectionId);
            if (lastSectionId && !promptedSections.includes(lastSectionId)) {
                setPromptedSections((prevSections) => [...prevSections, lastSectionId]);
            }
        }
    }, [messages, promptedSections]);

    useEffect(() => {
        const fetchWorkflow = async () => {
            try {
                const activeWorkflow = await getActiveWorkflow(context.apiBaseUrl);
                console.log(activeWorkflow);
                const parsedWorkflow = JSON.parse(activeWorkflow) as Survey;
                setWorkflow(parsedWorkflow);
            } catch (error) {
                console.error('Error fetching workflow:', error);
            }
        };

        fetchWorkflow();
    }, [context.apiBaseUrl]);

    useEffect(() => {
        const getTranscript = async (contact: string) => {
            try {

                console.log('getting transcript for: ', contact);
                //const transcriptData = await fetchTranscript(context.apiBaseUrl, contact);

                // console.log('transcript:', transcriptData);
                // if (transcriptData) {
                //     const mappedChatHistory = transcriptData.map((msg) => ({
                //         role: msg.sender,
                //         content: msg.text,
                //     }));
                //     setChatHistory(mappedChatHistory);
                //     setMessages(transcriptData);
                // }
            } catch (error) {
                console.error('Error fetching transcript:', error);
            }
        };

        if (encryptedData) {
            try {
                const secretKey = 'mySuperSecretKey123';
                const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
                const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

                if (decryptedData) {
                    console.log(decryptedData);
                    localStorage.setItem('personData', bytes.toString(CryptoJS.enc.Utf8));
                    setInitialContext(JSON.stringify(decryptedData));
                    setUserContact(decryptedData.WorkerNRIC);
                }

                if (!initialized.current) {
                    getTranscript(decryptedData.contact);
                    initialized.current = true;
                }
            } catch (error) {
                console.error('Error decrypting data:', error);
            }
        }
    }, [context.apiBaseUrl, encryptedData]);

    const saveTranscriptOnVisibilityChange = () => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                const personDataStr = localStorage.getItem('personData');
                const parsedPersonData = JSON.parse(personDataStr);

                saveSurveyCSVToDB(context.apiBaseUrl, parsedPersonData, workflow, messages);
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    };

    useEffect(saveTranscriptOnVisibilityChange, [messages, userContact, progress]);

    return {
        messages,
        chatHistory,
        promptedSections,
        workflow,
        userContact,
        initialContext,
        setMessages,
        setChatHistory,
    };
};

export default useSurveyBot;
