import axios, { AxiosResponse } from "axios";
import { MessageType, Survey } from '../common/types';

export const generateOpenAIResponse = async (apiBaseUrl: string, userInput?: string) => {
  try {    
    console.log('User input:',userInput);
    const response = await axios.post(`${apiBaseUrl}/generate/openai`, {
      message: userInput,
      language: "en",
    });

    let responseText = response.data.message;
    let sectionId = response.data.sectionId;
    let questionId = response.data.questionId;

    return {
      text: responseText,
      sender: "assistant",
      language: "en",
      sectionId: sectionId,
      questionId: questionId
    };
    
  } catch (error) {
    console.error("Error generating response:", error);
    return {
      text: "Sorry, there was an error generating the response.",
      sender: "assistant",
      language: "en",
      sectionId: null,
      questionId: null
    };
  }
};

export const generateGeminiResponse = async (apiBaseUrl: string, userInput?: string) => {
  try {    
    console.log('User section summary input:', userInput);
    const response = await axios.post(`${apiBaseUrl}/generate/gemini`, {
      message: userInput,
      language: "en",
    });

    let responseText = response.data.message;
    let sectionId = response.data.sectionId;
    let questionId = response.data.questionId;

    return {
      text: responseText,
      sender: "assistant",
      language: "en",
      sectionId: sectionId,
      questionId: questionId
    };
    
  } catch (error) {
    console.error("Error generating response:", error);
    return {
      text: "Sorry, there was an error generating the response.",
      sender: "assistant",
      language: "en",
      sectionId: null,
      questionId: null
    };
  }
};

export const generateGeminiCompletion = async (apiBaseUrl: string, userInput?: string) => {
  try {    
    console.log('User section summary input:', userInput);
    const response = await axios.post(`${apiBaseUrl}/generate/geminiComplete`, {
      message: userInput,
      language: "en",
    });

    let responseText = response.data.message;
    let sectionId = response.data.sectionId;
    let questionId = response.data.questionId;

    return {
      text: responseText,
      sender: "assistant",
      language: "en",
      sectionId: sectionId,
      questionId: questionId
    };
    
  } catch (error) {
    console.error("Error generating response:", error);
    return {
      text: "Sorry, there was an error generating the response.",
      sender: "assistant",
      language: "en",
      sectionId: null,
      questionId: null
    };
  }
};

export const generateSectionSummary = async (apiBaseUrl: string, userInput?: string) => {
  try {    
    //console.log('User section summary input:', userInput);
    const response = await axios.post(`${apiBaseUrl}/generate/summary`, {
      message: userInput,
      language: "en",
    });

    let responseText = response.data.message;

    return {
      text: responseText,
      sender: "user",
    };
    
  } catch (error) {
    console.error("Error generating response:", error);
    return {
      text: "Sorry, there was an error generating the response.",
      sender: "user",
    };
  }
};

export const fetchTranscript = async (apiBaseUrl: string, email) => {
  try {
    
    const response = await axios.post(`${apiBaseUrl}/transcript/getTranscript`, {
      email: email,
    });
    if (response && response.data && response.data.transcript) {
      return response.data.transcript;
    } else {
      console.warn("No transcript found in the response.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching transcript:", error);
    return null;
  }
};


export const saveSurveyTranscriptToDB = async (apiBaseUrl: string, personData, transcriptData, progressPercentage) => {
  console.log(transcriptData)
  try {
    
    await axios.post(`${apiBaseUrl}/transcript/saveTranscript`, {
      personData: personData,
      transcript: transcriptData,
      timestamp: new Date().toISOString(),
      progressPercentage: progressPercentage,
    });
    
    console.log("Survey transcript saved successfully: ", personData);
  } catch (error) {
    console.error("Error saving survey transcript:", error);
    return null;
  }
};

export const saveSurveyCSVToDB = async (
  apiBaseUrl: string, 
  personData: string,
  workflow: Survey,
  messages: MessageType[]
) => {
  try {
    
    console.log(personData);
  
    const questionAnswerPairs = [];
  
    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
  
      // Check if the message is from the assistant and has questionId and sectionId
      if (
        message.sender === 'assistant' &&
        message.questionId &&
        message.sectionId
      ) {
        const questionId = message.questionId;
        const sectionId = message.sectionId;
  
        // Retrieve the question text from the workflow
        let questionText = '';
        const section = workflow.sections.find(
          (sec) => sec.id === sectionId
        );
  
        if (section) {
          const question = section.questions.find(
            (q) => q.id === questionId
          );
          if (question) {
            questionText = question.text;
          }
        }
  
        // Look for the next message from the user as the answer
        const nextMessage = messages[i + 1];
        if (nextMessage && nextMessage.sender === 'user') {
          const answerText = nextMessage.text;
  
          // Add the question and answer to the array
          questionAnswerPairs.push({
            question: questionText,
            answer: answerText,
          });
  
          i++; // Skip the next message since we've already processed it
        } else {
          // Handle cases where the user did not respond
          questionAnswerPairs.push({
            question: questionText,
            answer: '', // Empty answer if user didn't respond
          });
        }
      }
    }
    console.log(questionAnswerPairs)
    const dataToSave = {
      personData,
      responses: questionAnswerPairs,
    };

    await axios.post(
      `${apiBaseUrl}/transcript/saveSurveyCSV`,
      dataToSave
    );
  } catch (error) {
    console.error('Error saving survey CSV to DB:', error);
  }
};

export const getActiveWorkflow = async (apiBaseUrl) => {
  try {
    const response: AxiosResponse<any> = await axios.get(`${apiBaseUrl}/generate/workflow`);
    const responseData = response.data.workflow.S;
    return responseData; 
  } catch (error) {
    console.error("Error fetching data: ", error);
    return []; 
  }
};

export const getWorkflow = async (apiBaseUrl, selectedWorkflowId) => {
  try {
    const response: AxiosResponse<any> = await axios.post(`${apiBaseUrl}/generate/workflowId`, {workflowId: selectedWorkflowId});
    const responseData = response.data;
    return responseData; 
  } catch (error) {
    console.error("Error fetching data: ", error);
    return []; 
  }
};

export const listWorkflows = async (apiBaseUrl) => {
  try {
    const response: AxiosResponse<any> = await axios.get(`${apiBaseUrl}/generate/listworkflow`);
    const responseData = response.data.workflow;
    return responseData; 
  } catch (error) {
    console.error("Error fetching data: ", error);
    return []; 
  }
};

export const getRespondentDataByNric = async (apiBaseUrl, nricInput) => {
  try {
    console.log(apiBaseUrl);
    console.log(nricInput);
    const response: AxiosResponse<any> = await axios.post(`${apiBaseUrl}/respondents/getRespondentDataByNRIC`, {nric: nricInput});
    const responseData = response.data.data;
    return responseData; 
  } catch (error) {
    console.error("Error fetching data: ", error);
    return []; 
  }
};
  

  