import { CLIENT } from "../common/constants";

const SINGPASS_REDIRECT_URL = "http://localhost:5156/myinfo/v3/authorise"

export const handleLogin = async (type) => {
    console.log('login handled!');
     // Store the type in state
    if (type === CLIENT.SINGPASS) {
      
      console.log("success")
      window.location.href = SINGPASS_REDIRECT_URL;
    } else if (type === CLIENT.WOG) {
      console.log("Redirecting to WOG Login Page");
      window.location.href = '/wog-login';
    } else if (type === CLIENT.DESIGNER) {
      console.log("Survey designer login page")
      window.location.href = '/sd-login'
    }
  };