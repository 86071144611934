import './asset/styles/App.css';
import { useState, useEffect } from 'react';
import { AppContextType, AppProvider } from './common/app-context';
import App from './App';
import { Alert, CircularProgress } from '@mui/material';
import { Amplify } from 'aws-amplify';

function AppConfigured() {
  const [config, setConfig] = useState<AppContextType | undefined>(undefined);
  const [error, setError] = useState<boolean | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const result = await fetch("/config.json");
        const appConfig = await result.json();
        Amplify.configure({Auth: appConfig.Auth});
        setConfig({
          apiBaseUrl: appConfig.REACT_APP_API_URL,
          isLoggedIn: false,
          role: null,
          login: () => {},
          logout: () => {},
        });
      } catch (e) {
        console.error(e);
        setError(true);
      }
    })();
  }, []);

  if (!config) {
    if (error) {
      return (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert title="Configuration error" severity="error">
            Error loading configuration from "
            <a href="/config.json" style={{ fontWeight: "600" }}>
              /config.json
            </a>
            "
          </Alert>
        </div>
      );
    }

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
        Loading
      </div>
    );
  }

  return (
    <AppProvider inApiBaseUrl={config.apiBaseUrl}>
      <App />
    </AppProvider>
  );
}

export default AppConfigured;
