import React, { useContext, useState } from "react";
import { signIn } from "aws-amplify/auth";
import { TextField, Button, Typography, Box, Container } from "@mui/material";
import { styled } from "@mui/system";
import { AppContext } from "../common/app-context";
import { Amplify } from 'aws-amplify';

const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
});

const SurveyDesignerLogin: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const context = useContext(AppContext);
  
  if (!context) {
    throw new Error("AppContext must be used within an AppProvider");
  }

  const { login } = context; 

  const handleLogin = async () => {
    try {
      let signInResult = await signIn({ username, password });
      console.log("Login successful!");
      console.log(signInResult);
      const userRole = 'designer'; 
      login(userRole);
      console.log('Logged in:', userRole); 
      // Redirect after login
      window.location.href = '/surveydesignerbot';
    } catch (error) {
      console.log(error);
      setError("Login failed. Please check your credentials.");
    }
  };

  // Function to handle Enter key press
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <StyledContainer maxWidth="sm">
      <Typography variant="h5" component="h1" className="mb-6">
        Survey Designer Login
      </Typography>

      <TextField
        label="Username"
        variant="outlined"
        fullWidth
        className="mb-4"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        onKeyDown={handleKeyPress} 
      />
      <TextField
        label="Password"
        type="password"
        variant="outlined"
        fullWidth
        className="mb-4"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={handleKeyPress} 
      />
      
      {error && (
        <Typography variant="body2" color="error" className="mb-4">
          {error}
        </Typography>
      )}

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleLogin}
      >
        Log In
      </Button>
    </StyledContainer>
  );
};

export default SurveyDesignerLogin;
